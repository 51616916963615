import cards1 from "./1.json";
import cards2 from "./2.json";
import cardsNL from "./nl.json";

const cards = [
  ...cards1,
  ...cards2,
  ...cardsNL,
];

export default cards;
